.recent-projects .hero {
  background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.35)), url("https://res.cloudinary.com/chudi/image/upload/v1542692743/ncs-tank/company-workers/group-photo.jpg"), linear-gradient(#00c694, rgba(16, 41, 35, 0.8));
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  width: 100%;
  min-height: 35rem; }

.recent-projects .slick-arrow {
  transition: 0.5s ease-in-out; }
  .recent-projects .slick-arrow.slick-disabled {
    opacity: 0.5; }

.recent-projects .slick-track .slick-slide {
  padding: 0 30px; }

@media only screen and (max-width: 576px) {
  .recent-projects .slick-track .slick-slide {
    padding: 0 5px; }
  .recent-projects .slick-arrow {
    width: 20px !important;
    height: 20px !important; } }

@media only screen and (max-width: 768px) {
  .recent-projects .slick-track .slick-slide {
    padding: 0 10px; }
  .recent-projects .slick-arrow {
    width: 23px !important;
    height: 23px !important; } }
