@font-face {
  font-family: circular;
  src: url("./fonts/Circular/CircularStd-Light.woff") format("woff");
  font-weight: 300;
  font-display: swap; }

@font-face {
  font-family: circular;
  src: url("./fonts/Circular/CircularStd-Book.otf") format("opentype");
  font-weight: 400;
  font-display: swap; }

@font-face {
  font-family: circular;
  src: url("./fonts/Circular/CircularStd-Medium.otf") format("opentype");
  font-weight: 500;
  font-display: swap; }

@font-face {
  font-family: circular;
  src: url("./fonts/Circular/CircularStd-Bold.otf") format("opentype");
  font-weight: 700;
  font-display: swap; }

@font-face {
  font-family: circular;
  src: url("./fonts/Circular/CircularStd-Black.otf") format("opentype");
  font-weight: 900;
  font-display: swap; }

.font-circular {
  font-family: circular, Arial, Helvetica, sans-serrif; }

.App {
  min-height: 100vh; }
  .App .debug {
    border: 2px solid gold; }
  .App .bg-custom-green {
    background: #f1faf1; }
  .App .content-wrapper {
    flex: 1 0 auto; }
  .App .banner__container {
    position: fixed;
    width: 100%;
    background-color: #cadbd1;
    bottom: 0;
    z-index: 100;
    padding: 0.8rem 0rem; }
    .App .banner__container .banner__text {
      font-size: 1.3rem;
      color: white;
      font-weight: bold;
      color: #172b1c;
      width: 100%;
      animation-name: marquee;
      animation-duration: 24s;
      animation-timing-function: linear;
      animation-iteration-count: infinite;
      animation-direction: reverse; }
    @media only screen and (max-width: 768px) {
      .App .banner__container {
        padding: 0.4rem 0rem; }
        .App .banner__container .banner__text {
          font-size: 1rem;
          animation-duration: 16s; } }

@keyframes marquee {
  from {
    transform: translateX(-20%); }
  to {
    transform: translateX(100%); } }
