.case-studies .hero {
  background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.35)), url("https://res.cloudinary.com/chudi/image/upload/v1542694467/ncs-tank/company-workers/worker-pressing-machine-1.jpg"), linear-gradient(#00c694, rgba(16, 41, 35, 0.8));
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  min-height: 40rem; }

.case-studies .study-container .study-card {
  margin-bottom: 5rem; }
  .case-studies .study-container .study-card:last-of-type {
    margin-bottom: 0; }
