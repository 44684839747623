.home .hero {
  background: linear-gradient(rgba(0, 0, 0, 0.45), rgba(0, 0, 0, 0.5)), url("https://res.cloudinary.com/chudi/image/upload/v1551953126/ncs-tank/assets/tank-new.png"), linear-gradient(#00c694, rgba(16, 41, 35, 0.8));
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0% -40%;
  width: 100%;
  min-height: 45rem; }

.home .logo-container {
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
  margin-top: 3.8rem; }
  .home .logo-container img {
    filter: grayscale(100%);
    cursor: pointer;
    width: 130px;
    transition: 0.4s ease-in-out; }
    .home .logo-container img.Chevron {
      width: 65px; }
    .home .logo-container img:hover {
      filter: grayscale(0%); }

.home .company-values .icon-container {
  max-width: 150px;
  height: 60px; }
  .home .company-values .icon-container img {
    max-height: 100%; }

.home .customer-container .customer-profile-picture {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #e1edea; }
  .home .customer-container .customer-profile-picture img {
    height: 100%;
    width: 100%;
    object-fit: cover; }

@media only screen and (max-width: 768px) {
  .home .hero {
    min-height: 23rem;
    background-position: center; }
  .home .logo-container {
    display: block;
    margin-top: 3rem; }
    .home .logo-container img {
      margin: 0 auto 3rem auto;
      display: block;
      width: 100px; }
      .home .logo-container img.Chevron {
        width: 50px; }
      .home .logo-container img:last-of-type {
        margin-bottom: 0; } }

@media (min-width: 576px) and (max-width: 768px) {
  .home .hero {
    min-height: 38rem; }
  .home .logo-container img {
    width: 120px; }
  .home .logo-container.Chevron {
    width: 60px; } }
