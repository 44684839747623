.about .hero {
  background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.3)), url("https://res.cloudinary.com/chudi/image/upload/v1542695209/ncs-tank/company-workers/works-looking-at-book.jpg"), linear-gradient(#00c694, rgba(16, 41, 35, 0.8));
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  min-height: 35rem; }

.about li {
  position: relative; }
  .about li::before {
    content: '';
    display: inline-block;
    position: absolute;
    height: 1rem;
    width: 1rem;
    background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCIgdmlld0JveD0iMCAwIDIwIDIwIj48Y2lyY2xlIGN4PSIxMCIgY3k9IjEwIiByPSIxMCIgZmlsbD0iI2FmZjFiNiIvPjxwYXRoIGQ9Ik0xNC4zIDUuMjZsLTYuNDQgNi41OS0xLjc1LTJBLjkxLjkxIDAgMCAwIDQuNjkgMTFsMi4wNyAzLjUxYTEuMTEgMS4xMSAwIDAgMCAxLjg2IDBjLjMzLS40NCA2LjU1LTguMjQgNi41NS04LjI0Ljc2LS45LS4xNy0xLjY3LS44Ny0xLjAxeiIgZmlsbD0iIzE1OTU3MCIvPjwvc3ZnPg==);
    background-size: contain;
    background-repeat: no-repeat;
    left: -1.8rem;
    top: 0.3rem; }

.about .company-values .icon-container {
  max-width: 150px;
  height: 60px; }
  .about .company-values .icon-container img {
    max-height: 100%; }

@media only screen and (max-width: 768px) {
  .about .hero {
    min-height: 26rem;
    background-position: left; } }
