.contact .toast {
  position: fixed;
  padding: 14px;
  min-width: 160px;
  max-width: 260px;
  animation: slideDown 0.8s;
  right: 50px;
  font-size: 15px;
  line-height: 25px;
  border-radius: 6px;
  top: 100px;
  z-index: 100; }
  .contact .toast.error {
    background-color: #fcebeae1;
    color: #ef5753; }
  .contact .toast.success {
    background-color: #e3fcec;
    color: #1f9d55; }

.contact .hero {
  background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.35)), url("https://res.cloudinary.com/chudi/image/upload/v1542701540/ncs-tank/company-workers/workers-looking-at-book-3.jpg"), linear-gradient(#00c694, rgba(16, 41, 35, 0.8));
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  min-height: 38rem; }

.contact form.contact_form input[type='text'],
.contact form.contact_form input[type='email'],
.contact form.contact_form textarea {
  transition: 0.4s ease-in-out; }
  .contact form.contact_form input[type='text'].has-error,
  .contact form.contact_form input[type='email'].has-error,
  .contact form.contact_form textarea.has-error {
    border-color: #ef5753 !important; }
  .contact form.contact_form input[type='text']:focus,
  .contact form.contact_form input[type='email']:focus,
  .contact form.contact_form textarea:focus {
    border-color: #1f9d55; }

.contact form.contact_form input[type='submit'] {
  transition: 0.4s ease-in-out; }
  .contact form.contact_form input[type='submit']:disabled {
    background: #dae1e7;
    cursor: not-allowed; }

@media only screen and (max-width: 576px) {
  .contact .hero {
    min-height: 23rem; } }

@keyframes slideDown {
  from {
    top: -140px; }
  to {
    top: 100px; } }
