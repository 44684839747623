nav.navbar {
  background: rgba(255, 255, 255, 0.95); }
  nav.navbar .brand-logo {
    width: 70px;
    height: 70px;
    display: block; }
  nav.navbar a.nav-link:after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    top: 1.5rem;
    border-bottom: 4px solid #38c172;
    transform: scaleX(0);
    transition: transform 0.3s ease-in-out; }
  nav.navbar a.nav-link:hover:after {
    transform: scaleX(1); }
  nav.navbar a.nav-link.active:after {
    border-bottom: 4px solid #38c172;
    transform: scaleX(1); }
  nav.navbar .team-dropdown {
    position: relative; }
    nav.navbar .team-dropdown .team-dropdown__text:after {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      top: 1.5rem;
      border-bottom: 4px solid #38c172;
      transform: scaleX(0);
      transition: transform 0.3s ease-in-out; }
    nav.navbar .team-dropdown .team-dropdown__text.active:after {
      border-bottom: 4px solid #38c172;
      transform: scaleX(1); }
    nav.navbar .team-dropdown img.chevron {
      margin-left: 0.3rem;
      width: 20px; }
    nav.navbar .team-dropdown .team-dropdown__content {
      position: absolute;
      display: none;
      background: white;
      border-radius: 4px;
      width: 130%;
      left: -10px;
      box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11), 0 5px 15px 0 rgba(0, 0, 0, 0.08);
      z-index: 999; }
      nav.navbar .team-dropdown .team-dropdown__content ul {
        text-align: center;
        list-style: none;
        padding: 0;
        cursor: pointer; }
        nav.navbar .team-dropdown .team-dropdown__content ul li {
          padding: 1rem 0;
          border-radius: 4px 4px 0 0; }
          nav.navbar .team-dropdown .team-dropdown__content ul li:last-of-type {
            border-radius: 0 0 4px 4px; }
          nav.navbar .team-dropdown .team-dropdown__content ul li:hover {
            background: #ddd; }
    nav.navbar .team-dropdown:hover .team-dropdown__content {
      display: block; }
  nav.navbar .menu-icon .navicon {
    background: black;
    display: block;
    height: 1px;
    position: relative;
    transition: background 0.2s ease-in-out;
    width: 24px; }
    nav.navbar .menu-icon .navicon:before, nav.navbar .menu-icon .navicon:after {
      content: '';
      background: black;
      display: block;
      height: 100%;
      width: 100%;
      position: absolute;
      transition: all 0.2s ease-in-out; }
    nav.navbar .menu-icon .navicon:before {
      top: 0.4rem; }
    nav.navbar .menu-icon .navicon:after {
      top: -0.4rem; }
  nav.navbar .menu-icon .menu-btn:checked ~ .navicon {
    background: transparent; }
  nav.navbar .menu-icon .menu-btn:checked ~ .navicon:before {
    transform: rotate(-45deg);
    top: 0; }
  nav.navbar .menu-icon .menu-btn:checked ~ .navicon:after {
    transform: rotate(45deg);
    top: 0; }

@media only screen and (max-width: 576px) {
  nav.navbar .team-dropdown .team-dropdown__text.active:after {
    border-bottom: 4px solid #38c172;
    transform: scaleX(0); }
  nav.navbar .team-dropdown .team-dropdown__content {
    left: 0;
    width: 100%; } }
