.project .project__hero {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background: linear-gradient(#00c694, rgba(16, 41, 35, 0.8));
  height: 30rem; }

.project .project__row {
  display: flex;
  width: 100%;
  align-items: flex-start;
  margin-bottom: 8rem; }
  .project .project__row .project__row__child {
    width: 100px;
    flex: 1;
    margin-right: 25px;
    height: 520px;
    background: #b3aeae;
    border-radius: 5px; }
    .project .project__row .project__row__child img {
      width: 100%;
      height: 100%;
      border-radius: 5px;
      object-fit: cover; }
    .project .project__row .project__row__child:last-of-type {
      margin-right: 0; }

@media only screen and (max-width: 768px) {
  .project .project__hero {
    padding: 5rem 2rem; }
  .project .project__row {
    display: block;
    margin-bottom: 6rem; }
    .project .project__row .project__row__child {
      display: block;
      width: 100%;
      height: 460px;
      margin-right: 0px;
      margin-bottom: 6rem; }
      .project .project__row .project__row__child:last-of-type {
        margin-bottom: 0; } }
