.directors .directors__hero {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background: linear-gradient(#00c694, rgba(16, 41, 35, 0.8));
  height: 28rem; }

.directors .director-profile {
  display: flex;
  align-items: flex-start;
  margin-bottom: 8rem; }
  .directors .director-profile .director-image {
    width: 140px;
    height: 140px;
    margin-right: 4rem;
    background: #b3aeae;
    border-radius: 50%;
    flex-shrink: 0; }
    .directors .director-profile .director-image img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%; }
  .directors .director-profile h1 {
    font-weight: bold;
    margin-top: 0.5rem;
    font-size: 1.5rem; }
  .directors .director-profile h2 {
    font-weight: normal;
    margin-top: 0.75rem;
    font-size: 1.2rem;
    opacity: 0.5; }
  .directors .director-profile p {
    margin-top: 1rem;
    font-size: 1.1rem;
    line-height: 2;
    font-weight: normal; }

@media only screen and (max-width: 992px) {
  .directors .directors__hero {
    height: 23rem; }
  .directors .director-profile {
    flex-direction: column;
    align-items: center;
    margin-bottom: 5rem; }
    .directors .director-profile .director-image {
      margin-right: 0; }
    .directors .director-profile h1 {
      text-align: center;
      margin-top: 1.2rem;
      line-height: 1.5; }
    .directors .director-profile h2 {
      margin-top: 0.5rem;
      text-align: center; }
    .directors .director-profile p {
      margin-top: 1rem; } }
